import { createBrowserRouter } from "react-router-dom";
import Layout from "../layout/MainLayout";
import Homepage from "../pages/homepage";
import Team from "../pages/About";
import News from "../pages/forensic";
import NewsDetails from "../pages/details";
import Contact from "../pages/contact";
import PrivacyPolicy from "../pages/privacy-policy";
import TermsOfUse from "../pages/terms-of-use";
import AuditedReports from "../pages/Reports";
// import AboutUs from "../pages/about-us";

export const router = createBrowserRouter([
    {
        path: "/",
        element: <Layout />,
        children: [
            {
                path: "/",
                element: <Homepage />
            },
            {
                path: "/forensic",
                element: <forensic />
            },
            // {
            //     path: "/news/:id",
            //     element: <NewsDetails />
            // },
            {
                path: "/about",
                element: <Team />
            },
            {
                path: "/Reports",
                element: <AuditedReports />
            },
            {
                path: "/contact",
                element: <Contact />
            },
            {
                path: "/privacy-policy",
                element: <PrivacyPolicy />
            },
            {
                path: "/terms-of-use",
                element: <TermsOfUse />
            },
            // {
            //     path: "/about-us",
            //     element: <AboutUs />
            // },
            
        ]
    }
])