import React from 'react';
import { motion } from 'framer-motion';
import t2 from "../../assets/img/t2.png";
import t3 from "../../assets/img/t3.png";
import t5 from "../../assets/img/t5.png";
import t6 from "../../assets/img/hhh.jpeg";

const TeamHero = () => {
  const data = [
    {
      id: 1,
      img: t6,
      name: "Piyush Shukla",
      designation: "Founder & Lead Security Researcher",
      expertise: "Lead Auditor, Zero-Day Research, Award-Winning Threat Hunter"
    },
    {
      id: 2,
      img: t2,
      name: "Ashmeet Singh",
      designation: "Head of Blockchain Research",
      expertise: "Head of Blockchain, Top Researcher in Blockchain, AI and ML"
    },
    {
      id: 3,
      img: t3,
      name: "Vaibhav Sethi",
      designation: "Head of Security Operations",
      expertise: "Head of Business Development, Ex Spearbit"
    },
    {
      id: 4,
      img: t5,
      name: "Mohit Yadav",
      designation: "Senior Security Researcher",
      expertise: "Blockchain Security Researcher, SafeEdges"
    }
  ];

  return (
    <div className="team-hero py-12 lg:py-20 bg-transparent">
      <div className="container mx-auto px-4 lg:px-8">
        <header className="text-center mb-12 lg:mb-16">
          <h2 className="text-white text-3xl lg:text-5xl font-bold mb-4">
          Meet our Leadership Team
          </h2>
          <p className="text-gray-300 text-lg lg:text-xl max-w-3xl mx-auto">
          Dedicated to safer blockchain, committed to Research and security.
          </p>
        </header>

        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-6 lg:gap-8">
          {data.map((member) => (
            <motion.div
              key={member.id}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="bg-white/5 backdrop-blur-sm hover:bg-white/10 transition-all duration-300 
                         rounded-2xl shadow-xl p-6 lg:p-8"
            >
              <img
                src={member.img}
                alt={member.name}
                className="w-24 h-24 lg:w-32 lg:h-32 mx-auto rounded-full mb-4 
                         border-2 border-blue-500/30"
              />
              <h3 className="text-xl lg:text-2xl font-bold text-white mb-2">
                {member.name}
              </h3>
              <p className="text-blue-400 font-medium mb-3">
                {member.designation}
              </p>
              <p className="text-gray-400 text-sm lg:text-base">
                {member.expertise}
              </p>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

const TeamPage = () => {
  return (
    <div className="min-h-screen bg-black-800">
      {/* Who We Are Section */}
      <section className="relative py-16 lg:py-24 px-4">
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
          className="max-w-4xl mx-auto"
        >
          <h1 className="text-4xl lg:text-6xl font-bold mb-8 text-center text-transparent 
                       bg-clip-text bg-gradient-to-r from-blue-400 to-teal-400">
            Securing the Future of Web3
          </h1>
          <div className="bg-white/5 backdrop-blur-sm rounded-2xl shadow-xl p-6 lg:p-10 
                        space-y-6">
            <p className="text-gray-300 text-lg lg:text-xl leading-relaxed">
              Safe Edges stands at the forefront of blockchain security, specializing in protecting 
              decentralized protocols and digital assets. Founded by Piyush Shukla, a recognized 
              authority in smart contract security, our team has secured over $2B in blockchain assets 
              through advanced auditing and continuous security monitoring.
            </p>
            <p className="text-gray-300 text-lg lg:text-xl leading-relaxed">
              Our expertise spans critical Web3 domains including DeFi protocol security, cross-chain 
              bridge protection, NFT marketplace security, and zero-day vulnerability research. We've 
              successfully prevented major exploits in leading protocols and established new standards 
              in blockchain security practices.
            </p>
          </div>

          <div className="mt-12 lg:mt-16 grid grid-cols-1 md:grid-cols-2 gap-6 lg:gap-8">
            <div className="bg-white/5 backdrop-blur-sm rounded-xl p-6 lg:p-8">
              <h3 className="text-xl lg:text-2xl font-bold text-white mb-4">
                Audit Excellence
              </h3>
              <p className="text-gray-400">
                Comprehensive smart contract audits with specialized focus on DeFi protocols, 
                yield farming systems, and cross-chain applications.
              </p>
            </div>
            <div className="bg-white/5 backdrop-blur-sm rounded-xl p-6 lg:p-8">
              <h3 className="text-xl lg:text-2xl font-bold text-white mb-4">
                Research Leadership
              </h3>
              <p className="text-gray-400">
                Pioneering research in MEV protection, ZK-proof implementation, and Layer 2 
                security solutions.
              </p>
            </div>
          </div>
        </motion.div>
      </section>

      {/* Team Section */}
      <TeamHero />
    </div>
  );
};

export default TeamPage;