import React from "react";
import { Download } from "lucide-react";

const reports = [
  {
    companyName: "Fuel chain",
    marketCap: "$682,3558",
    platform: "Fuel ignition",
    languages: "sway",
    auditStatus: "Ongoing",
    lastAudit: "2025",
    reportLink: "https://drive.google.com/drive/u/0/my-drive/fuel.pdf",
  },
  {
    companyName: "Ruscet",
    marketCap: "$82,358",
    platform: "Fuel ignition",
    languages: "sway",
    auditStatus: "Ongoing",
    lastAudit: "2025",
    reportLink: "https://drive.google.com/drive/u/0/my-drive/fuel.pdf",
  },
  {
    companyName: "swaylend",
    marketCap: "5,748,06",
    platform: "Fuel",
    languages: "sway",
    auditStatus: "Ongoing",
    lastAudit: "2024",
    reportLink: "https://drive.google.com/drive/u/0/my-drive/swaylend-report.pdf",
  },
  {
    companyName: "fluid protocol",
    marketCap: "$8,167,8",
    platform: "Fuel",
    languages: "sway",
    auditStatus: "Ongoing",
    lastAudit: "Nov, 2024",
    reportLink: "https://drive.google.com/drive/u/0/my-drive/crypto-report.pdf",
  },
  {
    companyName: "Griffy",
    marketCap: "$6,758,8",
    platform: "Fuel",
    languages: "sway",
    auditStatus: "Ongoing",
    lastAudit: "Dec, 2024",
    reportLink: "https://drive.google.com/drive/u/0/my-drive/bybit-report.pdf",
  },
  {
    companyName: "sprk",
    marketCap: "$5,748,06",
    platform: "Fuel",
    languages: "sway",
    auditStatus: "Ongoing",
    lastAudit: "Oct 24, 2024",
    reportLink: "https://drive.google.com/drive/u/0/my-drive/near-report.pdf",
  },
  {
    companyName: "fuelup",
    marketCap: "$5,748,3",
    platform: "Fuel",
    languages: "sway",
    auditStatus: "Ongoing",
    lastAudit: "Dec, 2024",
    reportLink: "https://drive.google.com/drive/u/0/my-drive/near-report.pdf",
  },
];

const ReportsPage = () => {
  const handleDownload = (reportLink, companyName) => {
    window.open(reportLink, '_blank', 'noopener,noreferrer');
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-black py-16">
      <div className="container mx-auto px-4">
        <div className="bg-gray-900/50 rounded-lg border border-gray-800 overflow-hidden">
          <div className="text-center p-8">
            <h2 className="text-3xl md:text-4xl font-bold bg-gradient-to-r from-blue-400 to-purple-500 text-transparent bg-clip-text">
              Audited Reports by Competing Lead Auditors
            </h2>
            <p className="text-gray-400 mt-4 max-w-2xl mx-auto">
              Each report reflects the outcome of a security audit conducted by one of three independent lead auditors working competitively to ensure the highest standards.
            </p>
          </div>
          
          <div className="p-6">
            <div className="overflow-x-auto rounded-lg border border-gray-800">
              <table className="w-full">
                <thead>
                  <tr className="bg-gray-800/50">
                    <th className="px-6 py-4 text-left text-sm font-medium text-gray-300">Company Name</th>
                    <th className="px-6 py-4 text-left text-sm font-medium text-gray-300">Market Cap</th>
                    <th className="px-6 py-4 text-left text-sm font-medium text-gray-300">Platform</th>
                    <th className="px-6 py-4 text-left text-sm font-medium text-gray-300">Languages</th>
                    <th className="px-6 py-4 text-left text-sm font-medium text-gray-300">Status</th>
                    <th className="px-6 py-4 text-left text-sm font-medium text-gray-300">Last Audit</th>
                    <th className="px-6 py-4 text-center text-sm font-medium text-gray-300">Report</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-800">
                  {reports.map((report, index) => (
                    <tr
                      key={index}
                      className="hover:bg-gray-800/30 transition-colors"
                    >
                      <td className="px-6 py-4">
                        <div className="font-medium text-white">{report.companyName}</div>
                      </td>
                      <td className="px-6 py-4 text-gray-300">{report.marketCap}</td>
                      <td className="px-6 py-4">
                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-700 text-gray-300">
                          {report.platform}
                        </span>
                      </td>
                      <td className="px-6 py-4">
                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium border border-gray-700 text-gray-300">
                          {report.languages}
                        </span>
                      </td>
                      <td className="px-6 py-4">
                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-500/20 text-green-400">
                          {report.auditStatus}
                        </span>
                      </td>
                      <td className="px-6 py-4 text-gray-300">{report.lastAudit}</td>
                      <td className="px-6 py-4 text-center">
                        <button
                          className="inline-flex items-center justify-center p-2 rounded-lg hover:bg-gray-700/50 hover:text-blue-400 transition-colors"
                          onClick={() => handleDownload(report.reportLink, report.companyName)}
                          title={`Download ${report.companyName} report`}
                        >
                          <Download className="h-4 w-4" />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportsPage;