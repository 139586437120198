import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import fuel from "../../assets/img/nick.jpg";
import oxiswap from "../../assets/img/l22.png";
import yuri from "../../assets/img/yuri.jpg";
import qoodo from "../../assets/img/qoodo.jpg";
import axir from "../../assets/img/axir.jpg";
import Antoine from "../../assets/img/antoine.jpg";
import griffy from "../../assets/img/griffy.jpg";
import vish from "../../assets/img/vish.jpg";
import arjun from "../../assets/img/arjun.jpg";

const testimonials = [
  {
    author: "Nick, Founder of Fuel Network",
    role: "@IAmNickDodson",
    quote:
      "Safe Edges helped us perform efficient and timely audits across the Fuel ecosystem. Their deep expertise in both Sway and Fuel has made them a serious player in the auditing space. The audit provided by Safe Edges Security allowed us to identify crucial improvements in our projects at Fuel Network. We highly recommend Safe Edges.",
    image: fuel,
  },
  {
    author: "Co-founder of Swaylend",
    role: "Yuri",
    quote: "Experience was truly awesome. Safe Edges showed a high level of sincerity from the jump, going through every single line of code with us on our kickoff call",
    image: yuri,
  },
  {
    author: "Oswald, Founder of Griffy Market",
    role: "@cmathemagician",
    quote: "Impressive work by Safe Edges. Highly recommend their services!",
    image: griffy,
  },
  {
    author: "Fuelwill Founder",
    role: "@oxiswap",
    quote:
      "Safe Edges identified vulnerabilities in our contracts and their recommendations improved our security significantly.",
    image: oxiswap,
  },
  {
    author: "VishNft",
    role: "Co-founder of Lync (Fuelup)",
    quote: "Safe Edges is one of the best auditors we have worked with so far",
    image: vish,
  },
  {
    author: "Antoine",
    role: "founderof Ruscet",
    quote: "10/10 They are hands down some of the absolute best security minds in the space.",
    image: Antoine,
  },
  {
    author: "Gabor Kiss",
    role: "Co-founder of Qoodo",
    quote: "Guardian demonstrates professionalism and attention to detail second to none.",
    image: qoodo,
  },
  {
    author: "Arjun Kalsy",
    role: "Chief Of Growth at Fuel Network",
    quote: "Safe Edges is one of the best auditors we have worked with so far.",
    image: arjun,
  },
  {
    author: "Axir",
    role: "Co-founder of Axir Wallet",
    quote: "Experience was truly awesome. Safe Edges showed a high level of sincerity from the jump, going through every single line of code with us on our kickoff call",
    image: axir,
  },
];

const MapToSafety = () => {
  return (
    <div className="bg-black py-16">
      <div className="container mx-auto px-6 text-center">
        <h2 className="text-white text-4xl font-extrabold mb-12 tracking-wide">Partner Comments</h2>
        <Swiper
          modules={[Pagination, Autoplay]}
          spaceBetween={50}
          slidesPerView={1}
          pagination={{ clickable: true }}
          autoplay={{ delay: 2000, disableOnInteraction: false }}
          className="pb-10"
        >
          {testimonials.map((testimonial, index) => (
            <SwiperSlide key={index}>
              <div className="bg-black-900 rounded-xl shadow-lg p-8 max-w-3xl mx-auto text-white">
                <p className="text-2xl font-serif italic leading-relaxed mb-6">"{testimonial.quote}"</p>
                <div className="flex flex-col items-center">
                  <img
                    src={testimonial.image}
                    alt={testimonial.author}
                    className="w-24 h-24 rounded-full object-cover border-4 border-gray-500 mb-4 shadow-md transform hover:scale-105 transition duration-300"
                  />
                  <p className="font-bold text-2xl text-white">{testimonial.author}</p>
                  <p className="text-gray-400 text-md mt-1">{testimonial.role}</p>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default MapToSafety;