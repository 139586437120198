import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
// Import all logo images (keep existing imports)
import logo1 from "../../assets/img/l1.png";
import logo2 from "../../assets/img/l2.png";
import logo3 from "../../assets/img/l3.png";
import logo4 from "../../assets/img/l4.png";
import logo5 from "../../assets/img/l5.png";
import logo6 from "../../assets/img/l6.png";
import logo7 from "../../assets/img/l7.png";
import logo8 from "../../assets/img/l8.svg";
import logo9 from "../../assets/img/l9.png";
import logo10 from "../../assets/img/l10.png";
import logo11 from "../../assets/img/l11.png";
import logo12 from "../../assets/img/l12.png";
import logo13 from "../../assets/img/l13.png";
import logo14 from "../../assets/img/l14.png";
import logo15 from "../../assets/img/l15.png";
import logo16 from "../../assets/img/l16.png";
import logo17 from "../../assets/img/l17.svg";
import logo18 from "../../assets/img/l18.png";
import logo19 from "../../assets/img/l19.svg";
import logo20 from "../../assets/img/l20.svg";
import logo21 from "../../assets/img/l21.png";
import logo22 from "../../assets/img/l22.png";
import logo23 from "../../assets/img/l23.svg";
import logo24 from "../../assets/img/l24.svg";
import logo25 from "../../assets/img/l25.png";

const TrustedBy = () => {
    const [showAll, setShowAll] = useState(false);
    const [logos, setLogos] = useState([]);

    // Combine all logos into one array with their URLs
    const allLogos = [
        { id: 1, img: logo1 },
        { id: 2, img: logo2 },
        { id: 3, img: logo3 },
        { id: 4, img: logo4 },
        { id: 5, img: logo5 },
        { id: 6, img: logo6 },
        { id: 7, img: logo7 },
        { id: 8, img: logo8, url: 'https://fuel.network' },
        { id: 9, img: logo9 },
        { id: 10, img: logo10 },
        { id: 11, img: logo11 },
        { id: 12, img: logo12 },
        { id: 13, img: logo13 },
        { id: 14, img: logo14 },
        { id: 15, img: logo15 },
        { id: 16, img: logo16, url: 'https://sprk.fi' },
        { id: 17, img: logo17, url: 'https://www.bako.global/' },
        { id: 18, img: logo23, url: 'https://swaylend.com' },
        { id: 19, img: logo20, url: 'https://redstone.finance' },
        { id: 20, img: logo9, url: 'https://hashcase.ca' },
        { id: 21, img: logo22, url: 'https://oxiswap.com' },
        { id: 22, img: logo18, url: 'https://griffy.app' },
        { id: 23, img: logo21, url: 'https://fluidprotocol.xyz/' },
        { id: 24, img: logo19, url: 'https://mira.ly' },
        { id: 25, img: logo25, url: 'https://lync.world/' },
        { id: 26, img: logo24, url: 'https://fuelet.app/' },
    ];
    const pageVariants = {
        initial: { 
            opacity: 0, 
            y: 50,
            scale: 0.9 
        },
        in: { 
            opacity: 1, 
            y: 0,
            scale: 1,
            transition: {
                duration: 0.4,
                type: "fade",
                stiffness: 80
            }
        },
        out: { 
            opacity: 0, 
            y: -50,
            scale: 0.9 
        }
    };

    const logoVariants = {
        hidden: { 
            opacity: 0, 
            y: 50,
            scale: 0.5,
            rotate: -180
        },
        visible: (index) => ({
            opacity: 1,
            y: 0,
            scale: 1,
            rotate: 0,
            transition: {
                delay: index * 0.1,
                type: "zoom",
                stiffness: 120,
                damping: 10
            }
        }),
        hover: {
            scale: 1.5,
            rotate: 5,
            transition: { 
                type: "fade", 
                stiffness: 300 
            }
        }
    };

    return (
        <motion.div 
            initial="initial"
            animate="in"
            exit="out"
            variants={pageVariants}
            className="py-6 bg-black"
        >
            <div className="container mx-auto">
                <div className="text-center mb-4">
                    <motion.p 
                        initial={{ opacity: 0, y: -20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: 0.2 }}
                        className="text-white text-xl font-semibold tracking-wide"
                    >
                        Trusted By Our Partners
                    </motion.p>
                </div>
                <div className="grid grid-cols-2 sm:grid-cols-4 lg:grid-cols-5 gap-4 items-center justify-items-center">
                    <AnimatePresence>
                        {(showAll ? allLogos : allLogos.slice(0, 10)).map((item, index) => (
                            <motion.div 
                                key={item.id}
                                initial="hidden"
                                animate="visible"
                                whileHover="hover"
                                variants={logoVariants}
                                custom={index}
                                exit={{ 
                                    opacity: 0, 
                                    scale: 0.5,
                                    transition: { duration: 0.3 }
                                }}
                                className="w-20 h-20 flex items-center justify-center"
                            >
                                {item.url ? (
                                    <a 
                                        href={item.url} 
                                        target="_blank" 
                                        rel="noopener noreferrer" 
                                        className="w-full h-full"
                                    >
                                        <motion.img 
                                            src={item.img} 
                                            alt={`Logo ${item.id}`} 
                                            className="w-full h-full object-contain"
                                        />
                                    </a>
                                ) : (
                                    <motion.img 
                                        src={item.img} 
                                        alt={`Logo ${item.id}`} 
                                        className="w-full h-full object-contain"
                                    />
                                )}
                            </motion.div>
                        ))}
                    </AnimatePresence>
                </div>
                {allLogos.length > 10 && (
                    <div className="text-center mt-4">
                        <motion.button
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            onClick={() => setShowAll(!showAll)}
                            className="px-4 py-2 border border-white text-white rounded-md hover:bg-white hover:text-black transition-colors duration-300 text-sm"
                        >
                            {showAll ? 'Show Less' : 'View All Partners'}
                        </motion.button>
                    </div>
                )}
            </div>
        </motion.div>
    );
};

export default TrustedBy;