import React, { useEffect, useState } from "react";
import logo from "../../assets/img/logo.png"; // Replace with the correct path to your logo
import { Link, NavLink } from "react-router-dom";
import bars from "../../assets/img/bars.png";

const styles = {
  logo: {
    width: "200px",
    height: "auto",
  },
};

const Nav = () => {
  const [show, setShow] = useState(false);
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const [scheduleDropdown, setScheduleDropdown] = useState(false);

  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);

    window.addEventListener("resize", handleResize);

    if (screenSize >= 768) {
      setShow(true);
    } else {
      setShow(false);
    }

    return () => window.removeEventListener("resize", handleResize);
  }, [screenSize]);

  const toggleMenu = () => {
    if (screenSize < 768) {
      setShow(!show);
    }
  };

  return (
    <nav className="py-5 relative z-20">
      <div className="container">
        <div className="flex justify-between items-center gap-3">
          <NavLink to="/" className="flex items-center gap-3">
            <img src={logo} alt="Safe Edges Logo" style={styles.logo} />
          </NavLink>
          <button onClick={toggleMenu} className="btn md:hidden block">
            <img src={bars} alt="Toggle Menu" />
          </button>
          <div
            className={`menu-items transition-[0.5s] items ${
              show || screenSize >= 768
                ? "translate-x-0 flex"
                : "translate-x-[-100%] hidden"
            } justify-end items-center gap-[10px] bg-[#252525] md:bg-transparent px-[18px] py-[15px] rounded-[20px] md:static md:flex-row md:translate-x-0`}
          >
            <Link
              to="/"
              onClick={toggleMenu}
              className="text-white block py-[20px] px-[35px] rounded-[20px] hover:bg-[#0e0e0f]"
            >
              Home
            </Link>
            <Link
              to="/about"
              onClick={toggleMenu}
              className="text-white block py-[20px] px-[35px] rounded-[20px] hover:bg-[#0e0e0f]"
            >
              About Us
            </Link>
            <Link
              to="/Reports"
              onClick={toggleMenu}
              className="text-white block py-[20px] px-[35px] rounded-[20px] hover:bg-[#0e0e0f]"
            >
              Audits
            </Link>
            <Link
              onClick={toggleMenu}
              to="https://safe-edges.medium.com/"
              className="text-white block py-[20px] px-[35px] rounded-[20px] hover:bg-[#0e0e0f]"
            >
              Blogs
            </Link>
            <div className="relative">
  <Link
    to="/"
    onClick={toggleMenu}
    className="text-white block py-[20px] px-[35px] rounded-[20px] hover:bg-[#0e0e0f] relative"
  >
    Chain Forensic
    <span className="absolute top-0 right-1 transform translate-x-2 -translate-y-2 bg-black-700 text-[10px] text-white px-2 py-[2px]">
      Coming Soon
    </span>
  </Link>
</div>

            {/* Schedule Meeting with Dropdown for Contact Us */}
            <div className="relative">
              <button
                onClick={() => setScheduleDropdown(!scheduleDropdown)}
                className="text-white block py-[20px] px-[35px] rounded-[20px] hover:bg-[#0e0e0f] flex items-center"
              >
                Schedule Meeting
                <span className="ml-2">{scheduleDropdown ? "▲" : "▼"}</span>
              </button>
              {scheduleDropdown && (
                <div className="absolute left-0 mt-2 w-full bg-[#252525] rounded-[10px] shadow-lg">
                  <Link
                    to="https://calendly.com/safeedges/30min"
                    onClick={toggleMenu}
                    className="block px-[20px] py-[10px] text-white hover:bg-[#0e0e0f] rounded-[10px]"
                  >
                    Schedule Meeting
                  </Link>
                  <Link
                    to="/contact"
                    onClick={toggleMenu}
                    className="block px-[20px] py-[10px] text-white hover:bg-[#0e0e0f] rounded-[10px]"
                  >
                    Contact Us
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Nav;
